import styles from "./AnaSayfaMain.module.css";
import { useNavigate } from 'react-router-dom';

const AnaSayfaMain = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.anaSayfaMainNew}>
      <div className={styles.frameParent}>
        <div className={styles.myfinansLogo31Parent}>
          <img
            className={styles.myfinansLogo31Icon}
            alt=""
            src="/myfinanslogo3-1@2x.png"
          />
          <div className={styles.textWrapper}>
            <div className={styles.text}>
              <span>my</span>
              <span className={styles.finans}>Finans</span>
            </div>
          </div>
        </div>
        <div className={styles.frameWrapper}>
          <div className={styles.buttonParent}>
            <div className={styles.button} onClick={() => navigate('/kayit')}>
              <b className={styles.button1}>Kayıt Ol</b>
            </div>
            <div className={styles.button2} onClick={() => navigate('/giris')}>
              <b className={styles.button1}>Giriş Yap</b>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.heroParent}>
        <div className={styles.hero}>
          <img
            className={styles.whatsappImage20241007At1}
            alt=""
            src="/whatsapp-image-20241007-at-193847portrait@2x.png"
          />
          <img className={styles.imageIcon} alt="" src="/image@2x.png" />
          <img className={styles.ssIcon} alt="" src="/ss@2x.png" />
          <img className={styles.testIcon} alt="" src="/test@2x.png" />
          <img className={styles.testIcon1} alt="" src="/test1@2x.png" />
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.yatrmlarnzKontrolAltndaParent}>
            <div className={styles.yatrmlarnzKontrolAltnda}>
              Yatırımlarınız kontrol altında !
            </div>
            <div className={styles.yatrmlarnz30Dan}>
              Yatırımlarınızı +30 dan fazla analiz çeşidi ve gelişmiş yapay
              zekalı taramalarımız ile güven altına alın.
            </div>
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.buttonGroup}>
              <div className={styles.button4}>
                <b className={styles.button1}>Şimdi Ücretsiz Kullan !</b>
              </div>
              <div className={styles.buttonContainer}>
              <div className={styles.button} onClick={() => window.open('https://apps.apple.com/tr/app/myfinans/id6670229290', '_blank')}>
              <img
                    className={styles.vuesaxboldappleIcon}
                    alt=""
                    src="/vuesaxboldapple.svg"
                  />
                  <b className={styles.button7}>Hemen İndir</b>
                </div>
                <div className={styles.button} onClick={() => window.open('https://play.google.com/store/apps/details?id=com.myFinans.myFinans', '_blank')}>
                <div className={styles.googlePlayStore1}>
                    <img className={styles.groupIcon} alt="" src="/ggg.png" />
                  </div>
                  <b className={styles.button7}>Hemen İndir</b>
                </div>
              </div>
            </div>
            <div className={styles.frameDiv}>
              <div className={styles.frameParent1}>
                <div className={styles.vuesaxboldarrowUpParent}>
                  <img
                    className={styles.vuesaxboldarrowUpIcon}
                    alt=""
                    src="/vuesaxboldarrowup.svg"
                  />
                  <div className={styles.bin}>100 bin +</div>
                </div>
                <div className={styles.bindenFazlaKullancmz}>
                  100 binden fazla kullanıcımız ve onlardan aldığımız geri
                  dönüşler ile birlikte gelişiyoruz.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.myfinansIleAkllAnalizDeneParent}>
        <div className={styles.myfinansIleAkllContainer}>
          <span
            className={styles.myfinansIleAkll}
          >{`myFinans ile akllı analiz deneyimini `}</span>
          <b>{`ÜCRTSİZ `}</b>
          <span className={styles.myfinansIleAkll}>yaşayın</span>
        </div>
        <div className={styles.frameParent2}>
          <div className={styles.frameParent3}>
            <div className={styles.frameParent4}>
              <div className={styles.frameParent5}>
                <div className={styles.vuesaxlinearmobileWrapper}>
                  <img
                    className={styles.vuesaxlinearmobileIcon}
                    alt=""
                    src="/vuesaxlinearmobile.svg"
                  />
                </div>
                <div className={styles.kademeliDerinliklerimizIle}>
                  Kademeli derinliklerimiz ile önünüzü görün !
                </div>
              </div>
              <div className={styles.kurumlarnVeKiilerin}>
                Kurumların ve kişilerin yaptığı işlemleri izleyin ve
                hisseleriniz gidişatını kestirin.
              </div>
              <div className={styles.animationCard1}>
                <div className={styles.image1Parent}>
                  <img
                    className={styles.image1Icon}
                    alt=""
                    src="/buk.png"
                  />
                  <img
                    className={styles.noiseIcon}
                    alt=""
                    src="/noise@2x.png"
                  />
                </div>
                <img
                  className={styles.image2Icon}
                  alt=""
                  src="/image-2@2x.png"
                />
              </div>
            </div>
            <div className={styles.frameParent4}>
              <div className={styles.frameParent5}>
                <div className={styles.vuesaxlinearmobileWrapper}>
                  <img
                    className={styles.uiIconlineChartlight}
                    alt=""
                    src="/ui-iconline-chartlight.svg"
                  />
                </div>
                <div className={styles.kademeliDerinliklerimizIle}>
                  Aracı kurumları takip edin !
                </div>
              </div>
              <div className={styles.kurumlarnVeKiilerin}>
                Takas analizi , AKD analizi gibi analizlerimiz asyesinde
                hisseleriniz üstündeki hareketleri görebilirsiniz.
              </div>
              <div className={styles.component1}>
                <div className={styles.image4Wrapper}>
                  <img
                    className={styles.image4Icon}
                    alt=""
                    src="/image-4@2x.png"
                  />
                </div>
                <div className={styles.frameParent8}>
                  <div className={styles.vuesaxlinearemptyWalletWrapper}>
                    <img
                      className={styles.vuesaxlinearemptyWalletIcon}
                      alt=""
                    />
                  </div>
                  <div className={styles.totalSavingsParent}>
                    <div className={styles.totalSavings}>Total Savings</div>
                    <div className={styles.div}>₹30,546.00</div>
                    <div className={styles.totalSavings}>
                      2 of 5 goals complete
                    </div>
                  </div>
                  <div className={styles.vuesaxlinearaddCircleParent}>
                    <img
                      className={styles.vuesaxlinearemptyWalletIcon}
                      alt=""
                    />
                    <b className={styles.addSavingGoals}>Add saving goals</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.groupParent}>
            <div className={styles.groupDiv}>
              <div className={styles.iconParent}>
                <div className={styles.icon}>
                  <div className={styles.vuesaxlinearmobileContainer}>
                  <img
                    className={styles.vuesaxlinearmobileIcon}
                    alt=""
                    src="/vuesaxlinearmobile.svg"
                  />
                  </div>
                </div>
                
                <div className={styles.kademeliDerinliklerimizIle}>
                  Tüm Platformlardan Kullanın
                </div>
              </div>
              <div className={styles.myfinansHemTelefonlarnzda}>
                myFinansı hem telefonlarınızdan hem de bilgisayarlarınızdan
                kullanabilirsiniz üstelik aynı anda çoklu cihaz desteği ile
                bağlantınız kesilmez !
              </div>
            </div>
            <div className={styles.component2}>
              <img
                className={styles.dottedMapWhiteBackground1Icon}
                alt=""
                src="/dotted-map-white-background-1@2x.png"
              />
              <div className={styles.frameParent9}>
                <div className={styles.frameParent10}>
                  <div className={styles.vuesaxlinearbankWrapper}>
                    <img className={styles.vuesaxlinearbankIcon} alt="" src />
                  </div>
                  <div className={styles.freeWrapper}>
                    <div className={styles.free}>Free</div>
                  </div>
                </div>
                <div className={styles.bankTransferParent}>
                  <div className={styles.totalSavings}>Bank Transfer</div>
                  <div className={styles.ellipseParent}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="/ellipse-2@2x.png"
                    />
                    <div className={styles.ellipseGroup}>
                      <div className={styles.frameItem} />
                      <div className={styles.frameItem} />
                      <div className={styles.frameItem} />
                    </div>
                    <img className={styles.iconOutlinedDirections} alt="" src />
                    <div className={styles.ellipseGroup}>
                      <div className={styles.frameItem} />
                      <div className={styles.frameItem} />
                      <div className={styles.frameItem} />
                    </div>
                    <img
                      className={styles.ellipseIcon}
                      alt=""
                      src="/ellipse-3@2x.png"
                    />
                  </div>
                  <div className={styles.rectangleParent}>
                    <img
                      className={styles.rectangleIcon}
                      alt=""
                      src="/rectangle-2@2x.png"
                    />
                    <div className={styles.free}>to</div>
                    <img
                      className={styles.rectangleIcon}
                      alt=""
                      src="/rectangle-2@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.amountParent}>
                  <div className={styles.amount}>Amount</div>
                  <div className={styles.div}>₹30,546.00</div>
                  <div className={styles.amount}>
                    <span className={styles.businessDay}>1-3 business day</span>
                    s
                  </div>
                </div>
              </div>
              <div className={styles.vectorParent}>
                <img className={styles.vectorIcon} alt="" src />
                <img className={styles.frameChild5} alt="" src />
                <div className={styles.ellipseWrapper}>
                  <img
                    className={styles.ellipseIcon}
                    alt=""
                    src="/ellipse-3@2x.png"
                  />
                </div>
                <div className={styles.ellipseFrame}>
                  <img
                    className={styles.ellipseIcon}
                    alt=""
                    src="/ellipse-3@2x.png"
                  />
                </div>
                <div className={styles.ellipseWrapper1}>
                  <img
                    className={styles.ellipseIcon}
                    alt=""
                    src="/ellipse-3@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.partnerlerimizParent}>
        <div className={styles.partnerlerimiz}>Partnerlerimiz</div>
        <div className={styles.sizeEnIyi}>
          Size en iyi performansı ve kesintisiz hizmeti sunmak için yaptığımız
          ilişkiler
        </div>
        <div className={styles.frameParent11}>
          <div className={styles.image4Container}>
            <img className={styles.image4Icon1} alt="" src="/image-41@2x.png" />
          </div>
          <div className={styles.image4Container}>
            <img className={styles.image2Icon1} alt="" src="/image-21@2x.png" />
          </div>
          <div className={styles.image4Container}>
            <img className={styles.image31Icon} alt="" src="/image-31@2x.png" />
          </div>
          <div className={styles.image4Container}>
            <img className={styles.image5Icon} alt="" src="/image-5@2x.png" />
          </div>
          <div className={styles.image4Container}>
            <img className={styles.image33Icon} alt="" src="/image-33@2x.png" />
          </div>
          <div className={styles.image4Container}>
            <img className={styles.image34Icon} alt="" src="/image-34@2x.png" />
          </div>
        </div>
      </div>
      <div className={styles.component4Wrapper}>
        <div className={styles.component4}>
          <div className={styles.getTheFinzoMobileAppParent}>
            <div className={styles.getTheFinzo}>Mobil Uygulamamız</div>
            <div className={styles.withThisPlatformyou}>
              Mobil uygulamamızı ücretsiz şekilde indirip kullanmaya
              başlayabilirsiniz !
            </div>
            <div className={styles.buttonParent1}>
              <div className={styles.button6}>
                <img
                  className={styles.vuesaxboldappleIcon}
                  alt=""
                  src="/vuesaxboldapple.svg"
                />
                <b className={styles.button7}>Şimdi İndir</b>
              </div>
              <div className={styles.button6}>
                <div className={styles.googlePlayStore1}>
                  <img className={styles.groupIcon} alt="" src="/ggg.png" />
                </div>
                <b className={styles.button7}>Şimdi İndir</b>
              </div>
            </div>
          </div>
          <div className={styles.frameParent12}>
            <div className={styles.layer1Parent}>
              <img className={styles.layer1Icon} alt="" src="/layer-1@2x.png" />
              <img className={styles.layer1Icon1} alt="" src="/layer-11.svg" />
            </div>
            <div className={styles.frameWrapper1}>
              <div className={styles.frameParent13}>
               
                <div className={styles.frameParent16}>
                  <div className={styles.finzoParent}>
                    <b className={styles.free}>Finzo</b>
                    <img
                      className={styles.mastercardIcon}
                      alt=""
                      src="/mastercard.svg"
                    />
                  </div>
                  <div className={styles.chip}>
                    <div className={styles.body1} />
                    <img
                      className={styles.chipChild}
                      alt=""
                      src="/vector-21.svg"
                    />
                    <img
                      className={styles.chipItem}
                      alt=""
                      src="/vector-11.svg"
                    />
                  </div>
                  <div className={styles.text7}>
                    <div className={styles.totalSavingsParent}>
                      <div className={styles.text6}>Card Holder</div>
                      <div className={styles.text12}>Jenny</div>
                    </div>
                    <div className={styles.frameParent15}>
                      <div className={styles.text6Parent}>
                        <div className={styles.text6}>Account Number</div>
                        <div className={styles.text41}>**** 3636</div>
                      </div>
                      <div className={styles.text6Parent}>
                        <div className={styles.text6}>Expiry date</div>
                        <div className={styles.text31}>02/30</div>
                      </div>
                    </div>
                  </div>
                  <img
                    className={styles.noiseIcon2}
                    alt=""
                    src="/noise2@2x.png"
                  />
                  <img
                    className={styles.cloth141Icon}
                    alt=""
                    src="/cloth14-1@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameParent18}>
            <div className={styles.layer1Group}>
              <img
                className={styles.layer1Icon}
                alt=""
                src="/layer-12@2x.png"
              />
              <img className={styles.layer1Icon1} alt="" />
            </div>
            <div className={styles.frameWrapper2}>
              <div className={styles.frameParent13}>
                <div className={styles.appleIphone11ProMaxScreenWrapper}>
                  <img
                    className={styles.appleIphone11ProMaxScreen}
                    alt=""
                    src="/apple-iphone-11-pro-max-screenshot-1-1@2x.png"
                  />
                </div>
                <img
                  className={styles.appleIphone11ProMaxScreen1}
                  alt=""
                  src="/apple-iphone-11-pro-max-screenshot-0-1@2x.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.component4Wrapper}>
        <div className={styles.component3}>
          <div className={styles.webPanelimizParent}>
            <div className={styles.getTheFinzo}>Web Panelimiz</div>
            <div className={styles.withThisPlatformyou}>
              Bilgisayarlarınızdan web panelimiz ile kesintisiz kullanım
              sağlayabilirsiniz
            </div>
            <div className={styles.buttonWrapper} onClick={() => navigate('/giris')}>

              <div className={styles.button14}>
                <b className={styles.button1}>Panele Giriş Yap</b>
              </div>
            </div>
          </div>
          <div className={styles.frameParent20}>
            <div className={styles.frameParent21}>
              <div className={styles.buttonParent2}>
                <div className={styles.button16}>
                  <img
                    className={styles.iconPlaceholder}
                    alt=""
                    src="/icon-placeholder@2x.png"
                  />
                  <b className={styles.button17}>IND Rupee</b>
                </div>
                <img className={styles.visaIcon1} alt="" src />
              </div>
              <div className={styles.frameParent22}>
                <div className={styles.text2Wrapper}>
                  <div className={styles.free}>Your balance</div>
                </div>
                <div className={styles.text1Parent}>
                  <div className={styles.text13}>₹4,05,694.80</div>
                  <img className={styles.vuesaxlineareyeIcon} alt="" src />
                </div>
              </div>
              <div className={styles.frameParent23}>
                <div className={styles.text6Container}>
                  <div className={styles.free}>Account Number</div>
                  <div className={styles.text42}>**** 3636</div>
                </div>
                <div className={styles.text6Container}>
                  <div className={styles.free}>Expiry date</div>
                  <div className={styles.text42}>02/30</div>
                </div>
              </div>
            </div>
            <div className={styles.ellipseParent1}>
              <img
                className={styles.frameChild9}
                alt=""
                src="/ellipse-31@2x.png"
              />
              <div className={styles.recievedParent}>
                <div className={styles.recieved}>Recieved</div>
                <b className={styles.euro}>104.80 EURO</b>
              </div>
            </div>
            <div className={styles.layer1Container}>
              <img
                className={styles.layer1Icon5}
                alt=""
                src="/layer-12@2x.png"
              />
              <img className={styles.layer1Icon6} alt="" src="/layer-11.svg" />
              <div className={styles.ellipseParent2}>
                <img
                  className={styles.frameChild9}
                  alt=""
                  src="/ellipse-31@2x.png"
                />
                <div className={styles.recievedParent}>
                  <div className={styles.recieved}>Recieved</div>
                  <b className={styles.euro}>104.80 EURO</b>
                </div>
              </div>
            </div>
            <div className={styles.image35Wrapper}>
              <img
                className={styles.image35Icon}
                alt=""
                src="/image-35@2x.png"
              />
            </div>
            <div className={styles.ellipseParent3}>
              <img
                className={styles.frameChild9}
                alt=""
                src="/ellipse-32@2x.png"
              />
              <div className={styles.recievedParent}>
                <div className={styles.recieved}>Transfer</div>
                <b className={styles.euro}>9,560 IND</b>
              </div>
            </div>
            <div className={styles.frameParent24}>
              <div className={styles.buttonParent2}>
                <div className={styles.button16}>
                  <img
                    className={styles.iconPlaceholder}
                    alt=""
                    src="/icon-placeholder1@2x.png"
                  />
                  <b className={styles.button17}>IND Rupee</b>
                </div>
                <img className={styles.visaIcon1} alt="" src />
              </div>
              <div className={styles.frameParent22}>
                <div className={styles.text2Wrapper}>
                  <div className={styles.free}>Your balance</div>
                </div>
                <div className={styles.text1Parent}>
                  <div className={styles.text13}>₹4,05,694.80</div>
                  <img className={styles.vuesaxlineareyeIcon} alt="" src />
                </div>
              </div>
              <div className={styles.frameParent23}>
                <div className={styles.text6Container}>
                  <div className={styles.free}>Account Number</div>
                  <div className={styles.text42}>**** 3636</div>
                </div>
                <div className={styles.text6Container}>
                  <div className={styles.free}>Expiry date</div>
                  <div className={styles.text42}>02/30</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.anaSayfaMainNewInner}>
        <div className={styles.frameWrapper3}>
          <div className={styles.frameParent27}>
            <div className={styles.frameParent28}>
              <div className={styles.frameParent29}>
                <div className={styles.myfinansLogo31Group}>
                  <img
                    className={styles.myfinansLogo31Icon}
                    alt=""
                    src="/myfinanslogo3-11@2x.png"
                  />
                  <div className={styles.textWrapper}>
                    <div className={styles.text}>
                      <span>my</span>
                      <span className={styles.finans}>Finans</span>
                    </div>
                  </div>
                </div>
                <div className={styles.frameParent30}>
                  <div className={styles.yatrmlarnzGlendirinWrapper}>
                    <div className={styles.yatrmlarnzGlendirin}>
                      Yatırımlarınızı güçlendirin !
                    </div>
                  </div>
                  <div className={styles.gelimelerdenHaberdarOlunParent}>
                    <div className={styles.gelimelerdenHaberdarOlun}>
                      Gelişmelerden Haberdar Olun
                    </div>
                    <div className={styles.groupWrapper}>
                      <div className={styles.frameParent31}>
                        <div className={styles.mailAdresinizWrapper}>
                          <div className={styles.gelimelerdenHaberdarOlun}>
                            Mail Adresiniz
                          </div>
                        </div>
                        <div className={styles.button20}>
                          <b className={styles.button21}>Abone Ol</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.lineDiv} />
            </div>
            <div className={styles.frameWrapper4}>
              <div className={styles.frameParent32}>
                
                <div className={styles.myfinansBirAmerika}>
                  myFinans bir Amerika Birleşik Devletleri şirketidir.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnaSayfaMain;